<template>
    <div class="footer app-footer">
      <div class="container">
        <div class="row d-flex">
          <div class="col logo-wrapper">
            <div class="logo">
              <nuxt-link to="/" rel="noreferrer" class="logo-link" :class="{'gap-0' : $config.brand === 'hnm'}">
                <img v-lazy="require(`~/assets/branding/${$texts.brand_slug}/logo.svg`)" :alt="$texts.brand_name" width="64" height="42" class="logo-img">
                <span class="logo-text">{{$texts.brand_name}}</span>
              </nuxt-link>
            </div>
            <div class="social-icons">
              <a class="social-icon" style="font-size: 0;" target="_blank" :href="$config.facebookLink">
                <img v-lazy="'/img/footer/fb.svg'" width="16" height="16" alt="facebook">
                facebook
              </a>
              <a target="_blank" style="font-size: 0;" :href="$config.twitterLink" class="social-icon">
                <img v-lazy="'/img/footer/twitter.svg'" width="16" height="16" alt="twitter">
                twitter
              </a>
              <a class="social-icon" style="font-size: 0;" target="_blank" :href="$config.instagramLink">
                <img v-lazy="'/img/footer/ig.svg'" width="16" height="16" alt="instagram">
                instagram
              </a>
            </div>
          </div>
          <div class="col col--07">
            <ul class="footer-nav">
              <li class="nav-li blog" v-show="$config.blogURL">
                <a :href="$config.blogURL" rel="noreferrer" class="nav-a">Blog</a>
              </li>
              <li class="nav-li">
                <nuxt-link class="nav-a" to="/how-it-works">How It Works</nuxt-link>
              </li>
              <li class="nav-li">
                <nuxt-link class="nav-a" to="/our-partners">Our Partners</nuxt-link>
              </li>
            </ul>
          </div>
          <div class="col">
            <ul class="footer-nav">
              <li class="nav-li">
                <nuxt-link class="nav-a" to="/about-us">About Us</nuxt-link>
              </li>
              <li class="nav-li help">
                <a class="nav-a" @click="showHelpModal">Contact Us</a>
              </li>
            </ul>
          </div>
          <div class="col footer-contacts-cards-wrapper">
            <div class="footer-contacts">
              <a :href="`mailto:${$config.helpEmail}`">
                <svg width="20" height="16" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.2 3.5L9 7.875L1.8 3.5V1.75L9 6.125L16.2 1.75M16.2 0H1.8C0.801 0 0 0.77875 0 1.75V12.25C0 12.7141 0.189642 13.1592 0.527208 13.4874C0.864773 13.8156 1.32261 14 1.8 14H16.2C16.6774 14 17.1352 13.8156 17.4728 13.4874C17.8104 13.1592 18 12.7141 18 12.25V1.75C18 1.28587 17.8104 0.840752 17.4728 0.512563C17.1352 0.184374 16.6774 0 16.2 0Z" fill="black"/>
                </svg>

                <span>{{ $config.helpEmail }}</span>
              </a>
            </div>
            <div class="card-brands">
              <img v-lazy="'/img/payments/mastercard.svg'" width="32" height="32"  loading="lazy" alt="mastercard">
              <img v-lazy="'/img/payments/visa.svg'" width="32" height="32"  loading="lazy" alt="visa">
              <img v-lazy="'/img/payments/ae.svg'" width="32" height="32"  loading="lazy" alt="american express">
              <img v-lazy="'/img/payments/discover.svg'" width="32" height="32"  loading="lazy" alt="discover">
            </div>
          </div>
        </div>
      </div>
      <div class="footer-partners">
        <div class="container">
          <div class="row">
            <div class="col first">
              <div class="footer-partner">
                <img v-lazy="require('~/assets/img/footer/ssl.svg')" width="188" height="24"  alt="ssl" />
              </div>
              <div class="footer-partner">
                <img v-lazy="require('~/assets/img/footer/aes.svg')" width="97" height="24" alt="aes" />
              </div>
            </div>
            <div class="col second">
              <div class="footer-partner arc">
                <img v-lazy="require('~/assets/img/footer/arc.svg')" width="112" height="22" alt="arc" />
              </div>
              <div class="footer-partner clin">
                <img v-lazy="require('~/assets/img/footer/clin.svg')" width="172" height="32"  alt="clin" />
              </div>
              <div class="footer-partner iata">
                <img v-lazy="require('~/assets/img/footer/iata.svg')"  width="106" height="32" alt="ista" />
              </div>
              <div class="footer-partner iatan">
                <img v-lazy="require('~/assets/img/footer/iatan.svg')" width="87" height="32" alt="iatan" />
              </div>
              <div class="footer-partner pci">
                <img v-lazy="require('~/assets/img/footer/pci-dss.svg')" width="30" height="30" alt="pci-dss" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col footer-contacts-cards-wrapper mobile">
            <div class="footer-contacts">
              <a :href="`mailto:${$config.helpEmail}`">
                <svg width="20" height="16" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.2 3.5L9 7.875L1.8 3.5V1.75L9 6.125L16.2 1.75M16.2 0H1.8C0.801 0 0 0.77875 0 1.75V12.25C0 12.7141 0.189642 13.1592 0.527208 13.4874C0.864773 13.8156 1.32261 14 1.8 14H16.2C16.6774 14 17.1352 13.8156 17.4728 13.4874C17.8104 13.1592 18 12.7141 18 12.25V1.75C18 1.28587 17.8104 0.840752 17.4728 0.512563C17.1352 0.184374 16.6774 0 16.2 0Z" fill="black"/>
                </svg>

                <span>{{ $config.helpEmail }}</span>
              </a>
            </div>
            <div class="card-brands">
              <img v-lazy="'/img/payments/mastercard.svg'" width="32" height="32"  loading="lazy" alt="mastercard">
              <img v-lazy="'/img/payments/visa.svg'" width="32" height="32"  loading="lazy" alt="visa">
              <img v-lazy="'/img/payments/ae.svg'" width="32" height="32"  loading="lazy" alt="american express">
              <img v-lazy="'/img/payments/discover.svg'" width="32" height="32"  loading="lazy" alt="discover">
              <img v-lazy="require('~/assets/img/footer/pci-dss.svg')" width="32" height="32" loading="lazy"  alt="pci-dss" />
            </div>
          </div>
        </div>
      </div>
      <div class="container footer-additional-nav-wrapper">
        <div class="row footer-additional-nav-mobile-row">
          <div class="col">
            <ul class="footer-additional-nav">
              <li class="nav-li">
                <nuxt-link to="/terms-and-conditions" rel="noreferrer" class="nav-a">Terms & conditions</nuxt-link>
              </li>
              <li class="nav-li">
                <nuxt-link to="/privacy-policy" rel="noreferrer" class="nav-a">Privacy & cookies</nuxt-link>
              </li>
              <div class="copyright">© {{ new Date().getFullYear() }} {{ $texts.copyright }}</div>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import helpModal from '../../mixins/help-modal'

export default {
  name: 'Footer',
  mixins: [helpModal],
}
</script>

<style src="./_footer.scss" lang="scss"></style>
